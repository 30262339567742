const initialState = () => ({
  redirect: "Home",
  envelopeId: "",
  events:{}
})

export default {
    state: initialState,
    getters: {
      redirect_to: state =>{
        return state.redirect
      },
      envelope: state =>{
        return state.envelopeId
      },
      events: state=> {
          return state.events
      }
    },
    mutations: {
      setRedirect(state, url) { 
            state.redirect = url
      },
      setEnvelopeId(state, envelopeId) {
          state.envelopeId = envelopeId
      },
      setTerms(state, data ){
        
        state.events[0] = data
      },
      setReadDocuments(state, data){
        state.events[1] = data
      },
      resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
      },
    },
    actions: {},
  }
  