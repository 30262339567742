import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSignaturePad from 'vue-signature-pad'
import VueSweetalert2 from 'vue-sweetalert2'
import { apolloProvider } from '@/services/graphql/apiConection'
import Skeleton from 'vue-loading-skeleton';
 

import router from './router'
import store from './store'
import App from './App.vue'
import VueDragscroll from 'vue-dragscroll'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSignaturePad)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueSweetalert2)
Vue.use(VueDragscroll)
Vue.use(Skeleton)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/assets/fonts/feather/iconfont.css')
//bootstrap

import bootstrapvue from 'bootstrap-vue'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(bootstrapvue)
  
Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
